import React/*, { useState }*/ from "react"
import Layout from "../components/layouts"
import SectionGrid2 from "../components/sections/grid/grid2"
import HeroInner2 from "../components/sections/hero/inner2"
import StoryCard from "../components/cards/story"
import { Link, graphql } from "gatsby"
import useTopCategories from "../hooks/useTopCategories"
import SectionTopCategories from "../components/sections/top-categories"
import SectionBase from "../components/sections/base"


const IndexPage = ({ data: { node, articles } }) => {
  return (
    <Layout>
      <Hero data={node.relationships.hero} />
      <Section1 data={articles.nodes} />
      <Section2 />
    </Layout>
  )
}

const Hero = ({ data }) => {

  const { title, subtitle } = data
  const url = data.relationships.image.localFile.publicURL
  const action1 = data.relationships.actions[0]
  //const action2 = data.relationships.actions[1]


  let backgrounds = []
  backgrounds = data.relationships.images.map( (m) => ( m.localFile.publicURL  ))
  if( backgrounds.length === 0 ){
    backgrounds.push(url)
  }

  return (
    <HeroInner2
      title={title}
      style={{ backgroundImage: `url("${url}")` }}
      description={subtitle}
      action1={action1}
      backgrounds={backgrounds}
    />
  )

}

const Section2 = () => {

  const items = useTopCategories()

  return (
    <SectionTopCategories
      items={items}
      className="lg:mx-24 mt-12"
    />
  )

}

const Section1 = ({ data }) => {

  //const [selectedCategory, SetSelectedCategory] = useState('')

  // const setCategory = (e) => {
  //   SetSelectedCategory(e.target.id)
  // }

  let articles = data
  // if (selectedCategory) {
  //   articles = articles.filter(article => article.relationships.tags.id === selectedCategory)
  // }


  // const tabs = [
  //   { label: Agent.translate('stories'), id: '4ce5e1b1-bb15-50a3-9f12-ec62c8ef265d' },
  //   { label: Agent.translate('news'), id: 'a6e108bc-1528-54ae-8bb2-5c176c2eed9c' },
  //   { label: Agent.translate('blog'), id: '22f6c736-8b91-5cb3-97fd-af1f778ab8de' },
  // ]


  // const links = tabs.map((link, index) => (
  //   <button
  //     key={index}
  //     id={link.id}
  //     className={" text-[25px] font-bold px-10 " + (link.id === selectedCategory ? ' button-primary' : ' button-white border-dark')}
  //     onClick={setCategory}
  //   >{link.label}</button>
  // ))

  return (

    <SectionBase className="">
      {/* <div className="flex flex-row space-x-10 space-x-reverse justify-center py-4">
        {links}
      </div> */}
      <Articles articles={articles} />
    </SectionBase>
  )
}



const Articles = ({ articles }) => {

  //console.log(articles)

  const cards = articles.map((item, index) => {

    const image = {
      url: item.relationships.image.localFile.publicURL,
      alt: item.image.alt
    }

    const html = item.relationships?.paragraphs[0]?.description[0]?.processed

    return (

        <StoryCard
          title={item.title}
          html={html}
          date={item.date}
          image={image}
          className="bg-white h-full "
          classNameImage="w-full h-[445px]"
          classNameDate="text-[15px] text-[#707070] px-12"
          classNameHtml="px-12"
          classNameTitle="py-6 px-12 "
        >
        <Link key={index} to={item.path.alias} className=""><div className="underline text-right px-12 pt-6 pb-12 block ">גלריה של { item.title }</div></Link>
        </StoryCard>

    )
  })


  return (
    <SectionGrid2
      className="lg:px-12 lg:py-12 bg-dark"
      classNameGrid="gap-x-20"
    >
      {cards}
    </SectionGrid2>
  )
}


export default IndexPage


export const query = graphql`
  query lobby($id: String!) {
    node: nodeBlogLobby(id: { eq: $id }) {
      id
      title
      metatag {
        attributes {
          content
          name
        }
        tag
      }
      path{
        alias
      }

      relationships {
        hero: field_hero {
          ...HeroContent
        }

      }
    }
    articles: allNodeArticle {
      nodes {
        id
        title
        date: field_date(formatString: "DD/MM/YYYY")
        path {
          alias
        }
        image: field_image {
          alt
        }
        relationships {
          image: field_image {
            ...Image
          }
          paragraphs: field_paragraph_multi {
            ...BlockContent
          }
          tags: field_tags {
            id
            name
            path {
              alias
            }
          }
        }
      }
    }
  }
`
